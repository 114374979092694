<template>
    <b-modal title="Form Sumber Kas Pengadaan" id="kas-modal" no-close-on-backdrop no-close-on-esc>
        <h1 class="text-danger text-center mb-2 mt-1">
            <strong>Rp {{ formatRupiah(totalPengadaan) }}</strong>
        </h1>
        <b-form-group label="Pilih Akun" class="mb-2">
            <v-select label="text" :options="akunOptions" v-model="akun"></v-select>
        </b-form-group>
        <b-form-group v-if="isKas" label="Pilih Kas" class="mb-2">
            <v-select label="text" :options="akunOptions" v-model="form.id_kas" :reduce="option => option.value"></v-select>
        </b-form-group>

        <template #modal-footer>
            <b-button variant="primary" @click.prevent="submit" :disabled="!isValidForm">Simpan</b-button>
        </template>
    </b-modal>
</template>
<script>
import {BModal, BFormGroup, BButton} from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
    components: {
        BModal, BFormGroup, BButton, vSelect
    },
    data: () => ({
        akun: null,
        form: {
            id: null,
            aktor: 'finance',
            id_akun: null,
            status: 1,
            id_kas: null
        }
    }),
    computed: {
        isValidForm() {

            if(!this.akun) {
                return false
            }

            if(this.isKas && !this.form.id_kas) {
                return false
            }

            return true
        },
        totalPengadaan() {
            return this.$store.state.asset.currentTotalPengadaan
        },
        isKas() {
            if(this.akun && this.akun.kategori && this.akun.kategori.kategori == 'Kas & Bank') {
                return true
            }

            return false
        },
        kasOptions() {
            return JSON.parse( JSON.stringify(this.$store.state.asset.kasOptions) )
        },
        akunOptions() {
            return JSON.parse( JSON.stringify(this.$store.state.asset.akunOptions) )
        }
    },
    methods: {
        submit() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Harap lengkapi form pemilihan sumber kas!'
                })
                return false
            }

            this.form.id_akun = this.akun.value
            this.form.id = this.$route.params.id
            this.$emit('submitKas', this.form)
        }
    }
}
</script>