<template>
    <b-overlay :show="loading">
        <header class="mb-2">
            <b-card>
                <b-row>
                    <b-col md="2" sm="12">
                        <p><strong>Tanggal Penyusutan</strong></p>
                        <p>{{ humanDate(penyusutan.tanggal) }}</p>
                    </b-col>
                    <b-col md="2" sm="12">
                        <p><strong>Tahun</strong></p>
                        <p>{{ penyusutan.tahun }}</p>
                    </b-col>
                    <b-col md="2" sm="12">
                        <p><strong>Bulan</strong></p>
                        <p class="mb-1">{{penyusutan.bulan ? penyusutan.bulan : 'kosong'}}</p>
                    </b-col>
                    <b-col md="2" sm="12">
                        <p><strong>Total Biaya</strong></p>
                        <p>Rp. {{ penyusutan.biaya ? formatRupiah(penyusutan.biaya) : "-" }}</p>
                    </b-col>
                    <b-col md="3" sm="12" v-if="penyusutan.id_akun == null && penyusutan.kas == null">
                        <p><strong>Status</strong></p>
                        <b-button class="mr-1" size="sm" variant="info" @click="confirmakun()"
                        >Tentukan Sumber Pengeluaran</b-button
                        >
                        
                    </b-col>
                    <b-col md="3" sm="12" v-if="penyusutan.id_akun > 0">
                        <p><strong>Status</strong></p>
                        <b-badge variant="light-primary" size="sm"
                        >Selesai dimasukkan ke akun</b-badge
                        >
                        <b-button size="sm" variant="danger" @click="confirmcancel()"
                        >Cancel Akun</b-button
                        >
                    </b-col>
                </b-row>
            </b-card>
        </header>
  
        <main>
            <b-modal
            id="modal-select2"
            v-model="showModalapprfinance"
            title="Pilih Akun dan Kas"
            ok-title="Tutup"
            ok-variant="secondary"
            ok-only
            centered
          >
            <b-form>
              <b-form-group label="Nominal Penyusutan">
                <h4>
                  <i class="text-success"><strong>Rp {{ formatRupiah(penyusutan.biaya) }}</strong></i>
                </h4>
              </b-form-group>
              <b-form-group label="Pilih Akun" label-for="vue-select-akun">
                <b-form-select id="vue-select-akun" v-model="form2.id_akun" :options="id_akun" class="mb-1" />
              </b-form-group>
              <b-form-group label="Pilih Kas" label-for="vue-select">
                <b-form-select id="vue-select" v-model="form2.id_kas" :options="id_kas" class="mb-1" />
                <i class="text-danger" v-if="invalidKas">Saldo Kas kurang</i>
              </b-form-group>
            </b-form>

            <template #modal-footer>
              <b-button
                :disabled="!isValidForm"
                type="submit"
                @click.prevent="simpanAkun"
                variant="primary"
                class="mr-1"
              >
                Simpan
              </b-button>
            </template>
          </b-modal>
            <!-- <b-card>
                <h3>Daftar Aset</h3>
                <b-button v-if="allowUpdate() && penyusutan.status == 0" variant="primary" class="mb-2" @click.prevent="addAsset">Pilih Aset</b-button>
                <b-table striped responsive="" hover :items="rincians" :fields="rincianFields" class="mb-2">
                    <template #cell(no)="row">
                        {{ (row.index + 1) }}
                    </template>
                    <template #cell(nama)="{item}">
                        <span v-if="item.asset">
                            {{ item.asset.nama }}
                        </span>
                        <i v-else class="text-danger">Aset tidak ditemukan</i>
                    </template>
                    <template #cell(harga_dasar)="{item}">
                        Rp {{ item.asset ? formatRupiah(item.asset.harga_dasar) : 0 }}
                    </template>
                    <template #cell(satuan)="{item}">
                        {{ item.asset && item.asset.satuan ? item.asset.satuan.satuan : '-' }}
                    </template>
                    <template #cell(harga)="{item}">
                        Rp {{ formatRupiah( parseInt(item.harga)) }}
                    </template>
                    <template #cell(status)="{item}">
                        Barang diterima
                        <feather-icon v-if="item.status == 0 || item.status == 2" icon="XIcon" class="text-danger" size="24" />
                        <feather-icon v-else icon="CheckIcon" class="text-info" size="24" />

                    </template>
                    <template #cell(action)="{index}">
                        <feather-icon v-if="(allowDelete() && penyusutan.status == 0)" class="cursor-pointer text-danger" @click="remove(index)" icon="MinusCircleIcon" size="24"></feather-icon>
                    </template>
                </b-table>
                <div class="d-flex justify-content-end">
                    <h4 class="mb-4">Total: Rp {{ formatRupiah(totalRincian) }}</h4>
                </div>
                <div class="d-flex justify-content-end">
                    <b-button v-if="allowUpdate() && penyusutan.status == 0" variant="primary" @click.prevent="submit" :disabled="(totalRincian < 1)">Simpan</b-button>
                    <b-button v-if="canManage && penyusutan.status == 5" variant="outline-success" @click.prevent="showApproveRincianModal">Tandai Barang Diterima</b-button>
                </div>
            </b-card> -->
        </main>

        <!-- Modal Asset -->
        <!-- <asset-modal :assets="assets" @submit="onChooseAsset"></asset-modal> -->
        <!-- / -->
        <!-- <kas-modal @submitKas="submitKas" v-if="canPay"></kas-modal> -->

        <!-- <rincian-modal v-if="canManage" @submitRincian="submitRincian"></rincian-modal> -->
    </b-overlay>
  </template>
  <script>
    import {BRow, BCol, BCard, VBTooltip,    BBadge, BButton, BTable, BModal,BFormSelect,BFormInput,BFormGroup, BOverlay} from 'bootstrap-vue'
    import AssetModal from './components/Modal.vue'
    import KasModal from './components/ModalKas.vue'
    import RincianModal from './components/ModalRincian.vue'

    export default {
        components: {
            RincianModal,
            VBTooltip,
            KasModal,
            AssetModal,
            BOverlay,
            BRow,
            BModal,BFormSelect,BFormInput,BFormGroup,
            BCol,
            BButton,
            BCard,
            BBadge,
            BTable
        },
        directives: {
            "b-tooltip": VBTooltip
        },
        computed: {
          invalidAkun() {
            const akun = this.id_akun.find(item => item.id == this.form2.id_akun)
            if(!akun) return false

            return akun && (parseInt(akun.saldo) < 1 || parseInt(akun.saldo) < parseInt(this.penyusutan.biaya))
          },
          invalidKas() {
            const kas = this.id_kas.find(item => item.id == this.form2.id_kas)
            if(!kas) return false

            return kas && (parseInt(kas.saldo) < 1 || parseInt(kas.saldo) < parseInt(this.penyusutan.biaya))
          },
          isValidForm() {
            if(!this.form2.id_akun || !this.form2.id_kas) {
              return false
            }

            if(this.invalidKas) {
              return false
            }

            return true
          },
            thereRincian() {
                return this.rincians.length > 0 && this.rincians.some(item => item.status && item.status == 1)
            },
            totalRincian(){
                if(this.rincians.length < 1) {
                    return 0
                }
                const rincians = this.rincians.filter(item => item.asset != null)
                const total = rincians.reduce((total, item) => {
                    return total += (item.jumlah*item.asset.harga_dasar)
                }, 0)
                return total
            }
        },
        data: () => ({
            showModalapprfinance: false,
            gudang: null,
            loading: false,
            assets: [],
            penyusutan: {
                no: null,
                keterangan: null,
                selesai: 0,
                tanggal: null
            },
            rincianFields: [
                {key: 'no', label: 'No', sortable: true},
                {key: 'nama', label: 'Nama Aset', sortable: true},
                {key: 'harga_dasar', label: 'Harga Dasar', sortable: true},
                {key: 'jumlah', label: 'Jumlah', sortable: true},
                {key: 'satuan', label: 'Satuan', sortable: true},
                {key: 'harga', label: 'Harga Total', sortable: true},
                {key: 'status', label: 'Barang Diterima'},
                {key: 'action', label: '#'},
            ],
            id_kas: [],
            id_akun:[],
            form2:{
                id_akun:null,
                id_kas:null,
            },
            formcancel:{},
            rincians: [],
            actionTypeFinance: 1,
            canApprove: false, // untuk owner
            canManage: false, // ntuk admin aset menempatkan barang
            canPay: false // nutuk finance pilih akun
        }),
        methods: {
            confirmcancel() {
      this.$swal({
        title: "Anda yakin?",
        text: "Ajuan ini dicancel",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(res => {
        if(res.value) {
          // item.fungsi = 1; // soft delete
          this.dicancel();
        }
      });
      // this.getKas();
      // this.getAkun();
    },
    async dicancel() {
      if (this.$route.params.id) {
        this.formcancel.id = this.$route.params.id;
      }
      const payload = this.formcancel;
      try {
        const ajuan = await this.$store.dispatch("asset/saveJurnalCancel", 
          [payload],
        );
        if (this.id) {
          this.getPenyusutan();
        //   this.getPb();
        } else {
          this.getPenyusutan();
        //   this.getPb();
          this.resetForm();
          this.displaySuccess({
            message: "Akun telah dicancel",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
            resetForm() {
    //   this.id = null;
    //   this.form.id = null;
    //   this.form.tanggal = new Date();
    this.form2.id_akun = null;
    this.form2.id_kas = null;
      this.showModalapprfinance = false;
    },
    async simpanAkun() {
      if(!this.isValidForm) {
        this.displayError({
          message: 'Harap lengkapi form sumber pengeluaran'
        })
        return false
      }
      if (this.$route.params.id) {
        this.form2.id = this.$route.params.id;
      }
      const payload = this.form2;
      try {
        const ajuan = await this.$store.dispatch("asset/saveJurnal", [payload]);
        if (this.id) {
          this.getPenyusutan()
        } else {
          this.getPenyusutan()
          this.resetForm();
          this.displaySuccess({
            message: "Penyusutan Asset sudah tersimpan",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
            confirmakun() {
      this.showModalapprfinance = true;
      this.getKas();
      this.getakun();
    },
    async getakun() {
      this.$store
        .dispatch("akun/getData", {})
        .then(() => {
          let akun = JSON.parse(JSON.stringify(this.$store.state.akun.datas));
          akun.map((item) => {
            item.value = item.id;
            const saldo = `${this.formatRupiah(item.saldo)}`
            item.text = `${item.nama} -> Rp ${saldo}`
          });
          this.id_akun = akun.filter(item => [this.JENIS_AKUN.kredit, this.JENIS_AKUN.debit_kredit].includes(item.jenis));
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getKas() {
      this.$store
        .dispatch("kas/getData", {})
        .then(() => {
          let kas = JSON.parse(JSON.stringify(this.$store.state.kas.datas));
          kas.map((item) => {
            item.value = item.id;
            const saldo = this.formatRupiah(item.saldo)
            item.text = `${item.nama} -> Rp ${saldo}`
          });
          this.id_kas = kas;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
            async getPenyusutan() {
                let penyusutan = await this.$store.dispatch('asset/getPenyusutanById', this.$route.params.id)
                if(!penyusutan) {
                    this.$router.push('/asset-penyusutan')
                }

                this.penyusutan = penyusutan
            },
        },
        async created() {
            this.loading = true
            this.getPenyusutan()
            // this.getRincian()
            // await this.getAssets()
            // await this.getGudangInfo()
            // await this.getPermissionApprove()
            // if(this.canPay) {
                this.getakun()
                this.getKas()
            // }
            this.loading = false
        }
    }
  </script>